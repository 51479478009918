import UtilityService from '@/services/UtilityService.js';
import UserService from '@/services/UserService.js';

export default {
    methods: {
        async getUserSelf() {
            this.loadinguser = true;
  
                UserService.getUser(this.accessToken, "self")
                .then(
                    (user => {
                        this.$set(this, "user", user);
                        this.loadinguser = false
                        
                    
                    }).bind(this)
                );
            
        },
        async getMembers(order_by, include_archived, selected_grades, sage_valid_only)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            let grades = ""
            if(selected_grades != "" && selected_grades != null)
            {
              grades = Object.values(selected_grades).join(',');
            }
            

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.member_search + "&order_by=" + order_by + "&include_archived=" + include_archived + "&grades=" + grades + "&sage_valid_only=" + sage_valid_only).then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getNmcMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&nmc_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getHcpcMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&hcpc_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getCopMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&cop_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getCosMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&cos_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getVisaMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&visa_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getNisccMembers(order_by)
        {
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&niscc_only=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getMembersWithLastWorkedDate(order_by)
        {
            
            if(order_by == "undefined" || order_by == "" || order_by == undefined)
            {
                order_by = ""
            }

            this.loading = true;
            UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&order_by=" + order_by + "&include_last_worked=1").then(
                (response) => {
                    this.members = response 
                    this.loading = false
                    this.searchPerformed = true
                }
            );
        },
        async getGrades() {
            UtilityService.getSimpleApiData(this.accessToken, "grades?include_internal=1").then(
                response => this.grades = response 
            );
        },
        async getGenders() {
            UtilityService.getSimpleApiData(this.accessToken, "genders").then(
                response => this.genders = response 
            );
        },
        async getNationalities() {
            UtilityService.getSimpleApiData(this.accessToken, "web/nationalities").then(
                response => this.nationalities = response 
            );
        },
        async getTimePeriods(booking_id) {
            UtilityService.getSimpleApiData(this.accessToken, "time_periods?booking_id=" + booking_id).then(
                response => this.time_periods = response 
            );
        },
        async getThisWeekYear() {
            UtilityService.getSimpleApiData(this.accessToken, "current_week_year")
            .then(
                (week_year => {
                    this.$set(this, "week", week_year.week);
                    this.$set(this, "year", week_year.year);
                }).bind(this)
            );
        },
        closeModal()
        {
            this.showModal = false
        },
        closeSmsModal()
        {
            this.showSmsModal = false
        },
        closeEmailModal()
        {
            this.showEmailModal = false
        },
        closeNoteModal()
        {
            this.getMemberNotes();
            this.showNoteModal = false
        },
        closeTimesheetModal()
        {
            this.showTimesheetModal = false
        },
        closeCancelModal()
        {
            this.getBookings();
            this.showCancelModal = false
        },
        closeViewBookingModal()
        {
            this.showViewBookingModal = false
        },
        
        getTodaysDate() {
            var d = new Date();
            
            d.setDate(d.getDate()); // Add seven days   + 7
            return d.toISOString().slice(0,10);
        },
        checkBookingGrade(client_type_id, grade_id)
        {
            // Trust type is number 4
            // CA Band 2 and Band 3 are IDs 2 and 3
            // CA PNH is 8
            if(client_type_id == 4 && grade_id == 8)
            {
                return true
            }
            else if(client_type_id != 4 && (grade_id == 2 || grade_id == 3))
            {
                return true
            }
            else{
                return false
            }
            
        }
        
    },
    created() {
        
    },
    mounted() {
     
    },
    destroyed() {
      
    },
    computed: {
        max_weeks : function() {
            if(this.year == 2023)
            {
                return 53
            }
            else
            {
                return 52
            }
            
        }
    }
  };